/*
 * 업무구분: 고객
 * 화 면 명: MSPCM115M
 * 화면설명: 전체고객
 * 작 성 일: 2022.05.26
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :key="compKey" class="msp" title="전체고객" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P()" @on-scroll-bottom="fn_LoadingData()"
   :topButton="true" ref="page" @on-scroll="fn_OnPageScrollEvent">
    <template #frame-header-fixed>
      <!-- 전체체크 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{ checkCount > 0 ? checkCount + '명 선택' : '총 ' + cMAllCustBasSVO.length + '명' }}</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- 검색결과 -->
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start" direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData115M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData115M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData115M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData115M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData115M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData115M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData115M !== {}" @click="fn_OpenMSPCM116P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData115M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[1] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">고객구분</span>
            <span class="txt">{{lv_ReturnStr[1]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[2] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">성별</span>
            <span class="txt">{{lv_ReturnStr[2]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[3] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">관계</span>
            <span class="txt">{{lv_ReturnStr[3]}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[4] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">연령</span>
            <span class="txt">{{lv_ReturnStr[4]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start" class="ns-list-area" >
      <!-- 조회결과 리스트 start -->
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <mo-list :list-data="cMAllCustBasSVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
              <!-- 체크박스 -->
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount" :disabled="checkDisabled" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title fexJsCtTy1">
                  <!-- 고객명 -->
                  <span class="name txtSkip flex-u" @click.stop="fn_OpenCustInfoBs(item.chnlCustId, item.custNm)">{{item.custNm}}</span>
                  <!-- 고객구분 -->
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="가망(백지)" shape="status" v-if="item.contYn === 'N' && item.chnlCustScCd === '01'">가망(백지)</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="가망" shape="status" v-else-if="item.contYn !== 'N' && item.chnlCustScCd === '01'">가망</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="임시" shape="status" v-else-if="item.chnlCustScCd === '02'">임시</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="본인모집" shape="status" v-else-if="item.chnlCustScCd === '03' && $bizUtil.isEmpty(item.insrdCustScCd)">본인모집</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="임시" shape="status" v-else-if="item.chnlCustScCd === '04' && $bizUtil.isEmpty(item.insrdCustScCd)">타인모집</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="관심" shape="status" v-else-if="item.chnlCustScCd === '05' && $bizUtil.isEmpty(item.insrdCustScCd)">관심</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="피_법인계약" shape="status" 
                    v-else-if="(item.chnlCustScCd === '03' || item.chnlCustScCd === '04' || item.chnlCustScCd === '05') && item.insrdCustScCd === '01'">피_법인계약</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="피_단체보험" shape="status" 
                    v-else-if="(item.chnlCustScCd === '03' || item.chnlCustScCd === '04' || item.chnlCustScCd === '05') && item.insrdCustScCd === '02'">피_단체보험</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="피_미니보험" shape="status" 
                    v-else-if="(item.chnlCustScCd === '03' || item.chnlCustScCd === '04' || item.chnlCustScCd === '05') && item.insrdCustScCd === '03'">피_미니보험</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="피_본인모집" shape="status" 
                    v-else-if="((item.chnlCustScCd === '03' || item.chnlCustScCd === '04' || item.chnlCustScCd === '05') && item.insrdCustScCd === '04')">피_본인모집</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="피_타인모집" shape="status" 
                    v-else-if="((item.chnlCustScCd === '03' || item.chnlCustScCd === '04' || item.chnlCustScCd === '05') && item.insrdCustScCd === '05')">피_타인모집</mo-badge>
                  <mo-badge class="badge-sample-badge lightblue sm white-space-nowrap" text="" shape="status" v-else></mo-badge>
                </div>
                <!-- 생년월일, 성별 -->
                <div class="list-item__contents__info">
                  <span>{{$commonUtil.dateDashFormat(item.dob)}}</span><em>|</em><span>{{item.sxdsCdNm.substr(0,1)}}</span>
                </div>
                <!-- 전화번호 -->
                <div v-if="item.telno" class="list-item__contents__info">
                  <span>{{item.telno}}</span>
                </div>
                <!-- 동의내역 -->
                <div class="list-item__contents__info">
                  <span>필수컨설팅 
                    <b v-if="item.mndtCnstgCnsntYn=='Y' && item.mndtCnstgCnsntRmdrDds <= 9999" class="crTy-blue2 pl10">Y (D-{{item.mndtCnstgCnsntRmdrDds}})</b>
                    <b v-else-if="item.mndtCnstgCnsntYn=='Y'" class="crTy-blue2 pl10">Y (D-9999+)</b>
                    <b v-else-if="item.mndtCnstgCnsntYn=='N'" class="crTy-orange2 pl10">N</b>
                    <b v-else>*</b>
                  </span>
                  <em>|</em>
                  <span>마케팅 
                    <b v-if="item.mktCnsntYn=='Y' && item.mktCnsntDday <= 9999" class="crTy-blue2 pl10">Y (D-{{item.mktCnsntDday}})</b>
                    <b v-else-if="item.mktCnsntYn=='Y'" class="crTy-blue2 pl10">Y (D-9999+)</b>
                    <b v-else-if="item.mktCnsntYn=='N'" class="crTy-orange2 pl10">N</b>
                    <b v-else>*</b>
                  </span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
      </ur-box-container>
      <!-- 조회결과 리스트 end -->

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

      <!-- 고객현황 바텀시트 start -->
      <mo-bottom-sheet ref="bottomSheetPnsta" class="ns-bottom-sheet closebtn" :close-btn="false">
        <template v-slot:title>
          고객 현황
          <div class="ns-btn-close" @click="fn_InitBottomSheet" name="닫기"></div>
        </template>
        <div class="fex-rw content-area mhAuto txt-center customer-stats-wrap">
          <span>
            <h6 class="crTy-bk7 fs16rem">고객 수</h6>
            <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cmTot }}</b>명</p>
          </span>
          <span>
            <h6 class="crTy-bk7 fs16rem">본인</h6>
            <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm03 }}</b>명</p>
          </span>
          <span>
            <h6 class="crTy-bk7 fs16rem">관심</h6>
            <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm05 }}</b>명</p>
          </span>
          <span>
            <h6 class="crTy-bk7 fs16rem">가망(백지)</h6>
            <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm00 }}</b>명</p>
          </span>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" @click="fn_InitBottomSheet" class="ns-btn-round blue">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 고객현황 바텀시트 end -->

      <!-- 확인 메시지 -->
      <mo-bottom-sheet ref="bottomSheetMsg"  class="ns-bottom-sheet">
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_BottomSheetMsg"/>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button v-if="lv_MSPCM144PFlag === 'Y'" componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPCM144P" name="확인">확인</mo-button>
              <mo-button v-else componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet('msg')" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 확인 메시지 end -->
      
      <!-- indexes: 초성검색 수동 세팅 -->
      <mo-index-scroll v-if="!isEmptyView && !showReturnStr || isInit" class="ns-index-scroll" :style="indexStyle" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="fn_ClickInitial"/>
      <mo-index-scroll v-if="!isEmptyView && showReturnStr && !isInit" class="ns-index-scroll" :style="indexStyle1" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="fn_ClickInitial"/>      

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
      <div v-if="cMAllCustBasSVO.length > 0" class="ns-height40"></div>
      
    </ur-box-container>
  </ur-page-container>
</template>

<script>


/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 전체고객 목록 검색
import MSPCM144P from '@/ui/cm/MSPCM144P' // 세미나 신청
import MSPCM146P from '@/ui/cm/MSPCM146P' // 대출추천
import MSPCM147P from '@/ui/cm/MSPCM147P' // 라벨출력
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM115M',
  // 현재 화면 ID
  screenId: 'MSPCM115M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM116P,
    MSPCM144P,
    MSPCM146P,
    MSPCM147P
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 여기서부터 사용
      pCustPtclrMatrCntnt: String,
      pChnlCustId: String,
      pCnsltNo: String,

      toastMsg: '', // 문자발송 toast 문구

      popup116: {}, // MSPCM116P 전체고객 목록 검색
      popup144: {}, // MSPCM144P 세미나 신청
      popup146: {}, // MSPCM146P 대출추천
      popup147: {}, // MSPCM147P 라벨출력
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열

      // index-scroll
      indexStyle: {
        top:'150px',
        height:'calc(100vh - 260px)',
        position:'fixed'
      },
      indexStyle1: {
        top:'250px',
        height:'calc(100vh - 330px)',
        position:'fixed'
      },
      scrollElementId: '',

      isFirst: true, // 초회 진입 여부
      isInit: false, // 검색 초기화 여부
      isEmptyView: false, // 데이터 없음 화면
      isSearched: false, // 조회이력 저장 변수
      isMngr: false, // 지점장 여부
      isCustCardExist: true, // 채널아이디 여부
      isOneOrMoreCheck: false, // 전체선택
      isDetailSearch: false,
      isInitialSearch: false, // 초성검색 이미지 visible여부
      checkDisabled: false, // 체크박스 비활성화 여부
      isLoading: false,

      compKey: 0,
      userInfo: {}, // 사용자정보 obj
      pOrgData115M: {}, // 최근 조직 조회 데이터
      lv_cnsltNo: '', // 컨설턴트 번호
      searchKeyword: '', // 고객명
      sxdsCd: '',  // 성별
      mnCustRltnCd: '', // 관계구분
      iDNumber: '', // 연령대구분
      lv_inputParamS1: {}, // 전체고객목록 조회 파라미터 Obj
      lv_inputParamS2: {}, // 고객요약정보 조회 파라미터 Obj
      lv_inputParamS3: {}, // 고객상세인적사항 정보 파라미터 Obj
      member: { // 선택한 고객 정보(메모 팝업에 사용)
        custPtclrMatrCntnt: '', // 고객특이사항
        chnlCustId: '' // 채널고객 ID
      },
      cMAllCustCntSVO: [],  // 고객현황 VO
      iCCdCustMktPsbInfoVO: { // 고객카드의 동의(BP정보)
        mktCnvsMthTelYn: ''
      },
      cmCustCardInfoSVO: { // 고객 상세정보 인적사항
        chnlCustId: ' ',
        custId: ' ',
        telno: ''
      },
      custListObj: [], // 문자수신 고객 list
      customerType: '', // 드롭다운 선택값 - MSPCM116P 로 이동
      customerTypes: [ // 드롭다운 목록 - MSPCM116P 로 이동
        { value: '', text: '전체고객' },
        { value: '03', text: '본인모집고객' },
        { value: '04', text: '타인모집고객' },
        { value: '05', text: '관심고객' },
        { value: '02', text: '임시고객' },
        { value: '01', text: '가망고객' },
      ],
      cMAllCustBasSVO: [], // 고객 조회용 Service IF VO
      searchInitial: '', // 초성검색 변수
      searchFlag: '', // 조회구분체크값 : 초성검색일때 'X'
      pageRowCnt: '20', // 페이지 count
      stndKeyList: [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}], // 더보기 변수
      stndKeyVal: '', // 더보기 변수
      lv_MSPCM144PFlag: 'N',  // MSPCM144P(세미나 신청) 진행 가능 여부
      lv_BottomSheetMsg: '', // 바텀시트 메시지

      crntScrollPosition: 0, // KeppAlive 스크롤 위치 변수
      pageScrollId: 'scroll_MSPCM115M'	 // 화면 Scroll 영역 ID
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems () {
      return this.cMAllCustBasSVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cMAllCustBasSVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData115M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },
  
  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cMAllCustBasSVO.length && this.cMAllCustBasSVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    this.fn_Init()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // index Scroll 설정    
    this.scrollElementId = this.$refs.page.getScrollElementId();
    document.getElementById(this.scrollElementId).addEventListener('scroll', (e) =>{
      const scrollTop = e.target.scrollTop;
      this.indexStyle = {
        top: Math.max(150 - scrollTop, 116) + 'px',
        height: `calc(100vh - ${Math.max(260 - scrollTop, 226)}px)`,
        position: 'fixed'
      }
    })

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM115M')
  },

  activated () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    // 진입 시 재조회
    this.compKey += 1
    
    // 2023.07.03 KeepAlive 재조회 안함(고객 조회 항목 유지)
    // this.fn_Init()
    // this.$forceUpdate()
    // console.log("★★★★★activated this.crntScrollPosition = "+this.crntScrollPosition)

    if(this.pageScrollId){
      const lv_Vm = this
      
      // 스크롤 이동 처리
      setTimeout(()=> {
        if (!document.getElementById(lv_Vm.pageScrollId)) return;

        document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.crntScrollPosition;
      }, 500);
    }
  },

  deactivated () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // cmStore 초기화
    window.vue.getStore('cmStore').dispatch('ADD', {})

    this.fn_InitBottomSheet()
    
    // console.log("★★★★★deactivated this.crntScrollPosition = "+this.crntScrollPosition)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // cmStore 초기화
    window.vue.getStore('cmStore').dispatch('ADD', {})

    this.fn_InitBottomSheet()
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          if (!this.$bizUtil.isEmpty(this.$route.params.pSearchKeyword)) {
            this.$route.params.pSearchKeyword = '' 
          }
          // 조회변수 초기화
          this.fn_InitValue()
          // 바텀시트 초기화
          this.fn_InitBottomSheet()
          // 재조회
          this.fn_Btn_S1Click()
        } else {
          // 스크롤 초기화
          this.crntScrollPosition = 0

          // 조회를 하지 않았으면 뒤로가기
          let custInfo = window.vue.getStore('cmStore').getters.getState.custInfo
          if (custInfo.isClear) {
            this.$router.push({ name: 'MSPBC002M' })
          } else {
            if (this.$route.query?._isMenuItem) {
              this.$router.push({name: 'MSPBC002M'})
              return
            }
            this.$router.go(-1)
          }
        }
      }

    },

    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 화면 진입 시 실행하는 초기 함수
    *
   ******************************************************************************/
    fn_Init () {
      // 사용자 정보
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

      // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
      if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
        this.isMngr = true
      }
    
      // 조회변수 초기화
      this.fn_InitValue()
      // 바텀시트 초기화
      this.fn_InitBottomSheet()

      // 조회
      this.fn_Btn_S1Click()
    },

    /******************************************************************************
    * Function명 : fn_SelAllCustCnt
    * 설명       : 전체 고객 수 조회(최초 로딩 시 실행)
    *
   ******************************************************************************/
    fn_SelAllCustCnt () {
      const trnstId = 'txTSSCM33S8'
      const auth = 'S'
      var lv_Vm = this
      let confirm = this.getStore('confirm')
      let pParams = {
        cnsltNo: ''
      }

      // 지점장 조회시 구분
      if (this.pOrgData115M.cnslt !== undefined && this.isMngr === true) {
        pParams.cnsltNo = lv_Vm.pOrgData115M.cnslt.key // 선택한 컨설턴트번호
      } else {
        pParams.cnsltNo = lv_Vm.userInfo.userId // 컨설턴트번호
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response !== undefined && response !== null && response.body !== undefined && response.body !== null) {
            let responseList = response.body.cMAllCustCntSVO

            if (responseList === null || responseList.length === 0) {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            } else {
              lv_Vm.isEmptyView = false
            }

            let resultList = {}
            resultList.cmTot = 0
            for (let i = 0; i < responseList.length; i++) {
              if ( responseList[i].custScCd === '01' ) {
                resultList.cm01 = parseInt(responseList[i].smtotCnt.trim())
                resultList.cmTot = resultList.cmTot + resultList.cm01
              } else if ( responseList[i].custScCd === '02' ) {
                resultList.cm02 = parseInt(responseList[i].smtotCnt.trim())
                resultList.cmTot = resultList.cmTot + resultList.cm02
              } else if ( responseList[i].custScCd === '03' ) {
                resultList.cm03 = parseInt(responseList[i].smtotCnt.trim())
                resultList.cmTot = resultList.cmTot + resultList.cm03
              } else if ( responseList[i].custScCd === '04' ) {
                resultList.cm04 = parseInt(responseList[i].smtotCnt.trim())
                resultList.cmTot = resultList.cmTot + resultList.cm04
              } else if ( responseList[i].custScCd === '05' ) {
                resultList.cm05 = parseInt(responseList[i].smtotCnt.trim())
                resultList.cmTot = resultList.cmTot + resultList.cm05
              } else if ( responseList[i].custScCd === '00' ) {
                resultList.cm00 = parseInt(responseList[i].smtotCnt.trim())
              }
            }
            lv_Vm.cMAllCustCntSVO = resultList

            // 고객현황 바텀시트 오픈
            lv_Vm.$refs.bottomSheetPnsta.open()
            // 전체고객 리스트 조회
            lv_Vm.fn_LoadingData()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_Btn_S1Click
     * 설명       : 고객명 조회
     ******************************************************************************/
    fn_Btn_S1Click () {
      this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}]
      this.stndKeyVal = '' // 더보기 최초조회
      this.cMAllCustBasSVO = []
      this.isDetailSearch = false
      this.isInitialSearch = false // 초성검색 이미지 visible여부
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.searchFlag = '' // 조회구분 체크값 (자음검색일땐 'X')

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData115M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData115M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      // 라우터 파라미터로 조회 고객명을 주는 경우: 제휴계약(MSPPM891M)
      if (!this.$bizUtil.isEmpty(this.$route.params.pSearchKeyword)) {
        this.searchKeyword = this.$route.params.pSearchKeyword
        // 상세검색조건 라벨 출력
        this.isSearched = true
        this.isInit = false
        this.lv_ReturnStr = [this.searchKeyword, '', '', '', '']
      }

      // post 호출 전 Progress 타입 설정. 미설정시 FULL(전체화면)이 기본값
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      if (this.isFirst) {
        // 고객현황 조회
        this.fn_SelAllCustCnt()
        this.isFirst = false
      } else {
        // 전체고객목록 조회
        this.fn_SelListAllCust(this.searchKeyword, this.customerType)
      }
    },

    /******************************************************************************
     * Function명 : fn_SetParamS1
     * 설명       : 파라미터 세팅(전체고객목록 조회)
     ******************************************************************************/
    fn_SetParamS1 (custNm, custScCd) {
      this.lv_inputParamS1= {} // Input 객체 초기화

      // 지점장 조회시 구분
      if (this.pOrgData115M.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParamS1.cnsltNo  = this.pOrgData115M.cnslt.key // 선택한 컨설턴트 번호
        this.lv_inputParamS1.fofOrgNo = this.pOrgData115M.fofOrg.key // 선택한 지점조직번호
      } else {
        this.lv_inputParamS1.cnsltNo  = this.userInfo.userId // 컨설턴트 번호
        this.lv_inputParamS1.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
      }
      this.lv_inputParamS1.custNm     = (custNm !== undefined) ? custNm : '' // 고객명
      this.lv_inputParamS1.custScCd   = (custScCd !== undefined) ? custScCd : '' // 고객구분코드
      this.lv_inputParamS1.deltYn     = 'N' // 삭제여부 - default
      this.lv_inputParamS1.pesnTypVal = 'G' // 컨설턴트 콤보 조회된 인사유형값 (G,F,R 등) - default
      this.lv_inputParamS1.searchFlag = this.searchFlag // 조회구분 체크값
      this.lv_inputParamS1.sxdsCd = this.sxdsCd // 1 : 여성, 2 : 남성
      this.lv_inputParamS1.mnCustRltnCd = this.mnCustRltnCd  //01:본인, 02:배우자, 03:자녀, 04:손자, 05:부모, 06:조부모, 07:친척, 08:기타가족
      this.lv_inputParamS1.iDNumber = this.iDNumber  // '':전체, 10: 20대미만, 20: 20~29세, 30: 30~39세, 40: 40~49세, 50: 50~59세, 60: 60~69세, 70: 70세이상
    },

    /******************************************************************************
     * Function명 : fn_SelListAllCust
     * 설명       : 전체고객목록 조회(최초 로딩 시 실행)
     ******************************************************************************/
    fn_SelListAllCust (custNm, custScCd) {
      // 마지막 페이지 return
      if (this.stndKeyVal === 'end') return

      const lv_Vm = this
      // const trnstId = 'txTSSCM33S1'
      const trnstId = 'txTSSCM49S2'
      const auth = 'S'
      let pParams = {}

      // 파라미터 세팅
      this.fn_SetParamS1(custNm, custScCd)

      pParams = this.lv_inputParamS1

      // 로딩이미지 visible 여부 세팅
      // let isLoadingImgVisible = this.isInitialSearch

      this.isLoading = true
      this.lv_TotalCnt = 0

      setTimeout(function () {
        lv_Vm.isInitialSearch = false // 초성검색 이미지 visible false처리
      }, 1000)

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body.cMAllCustBasSVO

            for (let i = 0; i < responseList.length; i++) {
              responseList[i].checked = false
             //  let lv_Test = lv_Vm.$commonUtil.createDecryptMiniInstance().decryptMini(responseList[i].telnoEnc)
              // console.log('복호화한 값은????' + lv_Test)
            }

            if (lv_Vm.stndKeyVal !== '' ) { // 더보기
              for (let i = 0; i < responseList.length; i++) {
                lv_Vm.cMAllCustBasSVO.push(responseList[i])
              }
            } else { // 1페이지
              lv_Vm.cMAllCustBasSVO = responseList
            }

            if (responseList.length === 0) {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            } else {
              lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

          // 더보기 키값
          lv_Vm.stndKeyList = response.trnstComm.stndKeyList

          // 다음 데이터 존재시 more, 없으면 end
          if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
            lv_Vm.stndKeyVal = 'end'
          } else {
            lv_Vm.stndKeyVal = 'more'
          }

          let resultList = lv_Vm.cMAllCustBasSVO

          lv_Vm.lv_TotalCnt = (resultList === null) ? 0 : resultList.length

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          lv_Vm.isLoading = false
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : 고객목록 infinite 로딩
     ******************************************************************************/
    fn_LoadingData () {
      // 더보기 시 전체체크 되어있으면 전체체크 해제
      if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
        this.isOneOrMoreCheck = false

        // this.fn_CheckAll()
      }

      // 전체고객목록 조회
      // post 호출 전 Progress 타입 설정. 미설정시 FULL(전체화면)이 기본값
      window.vue.getStore('progress').dispatch('PART')

      if (!this.isLoading) {
        this.fn_SelListAllCust(this.searchKeyword, this.customerType)
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.cMAllCustBasSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cMAllCustBasSVO) {
            if (this.isOneOrMoreCheck) {
              this.cMAllCustBasSVO[n].checked = true
            } else {
              this.cMAllCustBasSVO[n].checked = false
            }
          }

          // 고객 체크 시 BottomSheet
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount () {

      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      if (this.checkCount < 1) {
        this.fn_InitBottomSheet()
      }
      // 고객 체크 시 BottomSheet
      // 배열로 넘기기 (Array.isArray(selectItems) === true 로 확인)
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },
    
    /******************************************************************************
     * Function명 : fn_ClickInitial
     * 설명       : 초성 클릭 핸들러
     ******************************************************************************/
    fn_ClickInitial (keyword) {
      if (this.isInitialSearch === true) {
        return
      }

      this.isInitialSearch = true // 초성검색 이미지 visible여부
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.searchFlag = 'X' // 조회구분 체크값 (자음검색일땐 'X')

      this.fn_ValiInitial(keyword) // 초성값 세팅

      this.searchKeyword = '' // 고객명 초기화

      this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}]
      this.stndKeyVal = '' // 더보기 최초조회
      this.cMAllCustBasSVO = []
      this.isDetailSearch = false

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData115M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData115M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      }

      // 조회이력 저장
      this.isSearched = true

      // 스크롤 초기화
      this.crntScrollPosition = 0

      // 전체고객목록 조회
      this.fn_SelListAllCust(this.searchInitial, this.customerType)
    },
    
    /******************************************************************************
     * Function명 : fn_ValiInitial
     * 설명       : 초성값 세팅
     ******************************************************************************/
    fn_ValiInitial(keyword) {
      this.searchInitial = this.$cmUtil.getNameInitial(keyword)
    },

    /******************************************************************************
     * Function명 : fn_InitValue
     * 설명       : 주요 변수 초기화
     ******************************************************************************/
    fn_InitValue() {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init) 

      this.pOrgData115M = {} // 조직 조회 데이터
      this.searchKeyword = '' // 고객명
      this.customerType = '' // 고객구분
      this.sxdsCd = ''  // 성별
      this.mnCustRltnCd = ''  // 관계구분
      this.iDNumber = ''  // 연령구분

      this.isSearched = false // 검색이력 초기화
      this.isInit = true  // 검색초기화 여부
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet (flag) {
      let lv_Vm = this

      // 확인 메시지 바텀시트만 종료
      if (flag === 'msg') {
        if (lv_Vm.$refs !== undefined) {
          if (lv_Vm.$refs.bottomSheetMsg !== undefined) {
            lv_Vm.$refs.bottomSheetMsg.close()
          }
        }
        return
      }

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetPnsta !== undefined) {
          lv_Vm.$refs.bottomSheetPnsta.close()
        }
        if (lv_Vm.$refs.bottomSheetMsg !== undefined) {
          lv_Vm.$refs.bottomSheetMsg.close()
        }
      }
    },

    /******************************************************************************
     * Function명: fn_OpenMSPCM144P
     * 설명: 세미나 신청 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM144P () {
      let lv_Vm = this
      lv_Vm.fn_InitBottomSheet('msg')  // 확인 메시지 바텀시트 종료

      this.popup144 = this.$bottomModal.open(MSPCM144P, {
        properties: {
          pCustList: this.selectItems,
          pCnsltNo: this.userInfo.userId
        },
        listeners: {
          onPopupClose: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup144)
          },
          onPopupConfirm: () => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup144)
            // 바텀시트 초기화
            lv_Vm.fn_InitBottomSheet()
            // 고객명 조회
            lv_Vm.fn_Btn_S1Click()
          }
        }
      })
    },


    /******************************************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM116P () {
      let lv_Vm = this

      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM115M',
          pOrgData: this.pOrgData115M, // 최근 조직 조회 데이터
          pSearchKeyword: this.searchKeyword, // 고객명
          pCustomerType: this.customerType, // 고객구분
          pSxdsCd: this.sxdsCd, // 성별
          pMnCustRltnCd: this.mnCustRltnCd, // 관계
          pIDNumber: this.iDNumber, // 연령
          pIsMngr: this.isMngr, // 지점장 여부
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup116)
            lv_Vm.fn_InitBottomSheet()

            lv_Vm.searchKeyword = pData.rtnData.searchKeyword
            lv_Vm.customerType = pData.rtnData.customerType
            lv_Vm.sxdsCd = pData.rtnData.sxdsCd
            lv_Vm.mnCustRltnCd = pData.rtnData.mnCustRltnCd
            lv_Vm.iDNumber = pData.rtnData.iDNumber

            // 마지막 조직 조회 데이터 셋팅
            lv_Vm.pOrgData115M = pData.rtnData.pOrgData

            // 조회이력 저장
            lv_Vm.isSearched = true
            // 검색 초기화 여부
            lv_Vm.isInit = pData.isInit
            if (lv_Vm.isInit) lv_Vm.isSearched = false
            // 조회조건 텍스트 세팅
            lv_Vm.lv_ReturnStr = pData.srchOpt
            // 조회를 한번이라도 한 경우 파라미터로 넘어온 고객명 삭제
            if (!this.$bizUtil.isEmpty(this.$route.params.pSearchKeyword)) {
              this.$route.params.pSearchKeyword = '' 
            }

            // 스크롤 초기화
            lv_Vm.crntScrollPosition = 0

            // 고객명 조회
            lv_Vm.fn_Btn_S1Click()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 네임UI 바텀시트 (고객명터치시)
     ******************************************************************************/
    fn_OpenCustInfoBs (chnlCustId, custNm) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      if(this.pOrgData115M.cnslt === undefined || this.pOrgData115M.cnslt === null || this.pOrgData115M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData115M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : chnlCustId, //고객채널 ID
        parentId   : 'MSPCM115M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : custNm,
        contNo : ''
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              
              /**
               * (2023.07.11 전체고객 조회, 체크박스 해제 / 김미정 프로)
               * "예" 버튼 클릭시, 전체고객 조회 초기화
               * 메인 홈으로 이동시, 체크박스 해제
               */
              lv_Vm.crntScrollPosition = 0 // 스크롤 초기화

              // 조회를 한번이라도 한 경우 전체 검색
              if (!this.$bizUtil.isEmpty(this.$route.params.pSearchKeyword)) {
                this.$route.params.pSearchKeyword = '' 
              }
              // 조회변수 초기화
              lv_Vm.fn_InitValue()
              // 바텀시트 초기화
              lv_Vm.fn_InitBottomSheet()
              // 재조회
              lv_Vm.fn_Btn_S1Click()

              if (!lv_Vm.isSearched) {
                // 전체선택 해제 (2023.07.11 해당 팝업으로 나갔을때만, 체크박스 해제 / 김미정 프로)
                lv_Vm.isOneOrMoreCheck = false
                lv_Vm.fn_CheckAll()

                // 조회를 하지 않았으면 뒤로가기
                let custInfo = window.vue.getStore('cmStore').getters.getState.custInfo
                if (custInfo.isClear) {
                  this.$router.push({ name: 'MSPBC002M' })
                } else {
                  if (this.$route.query?._isMenuItem) {
                    this.$router.push({name: 'MSPBC002M'})
                    return
                  }
                  this.$router.go(-1)
                }
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
    /******************************************************************************
     * Function명 : fn_OnPageScrollEvent
     * 설명       : 고객목록 스크롤 이벤트 메서드
     ******************************************************************************/
    fn_OnPageScrollEvent (e) {
      let target = e.target;      
      if(!target) return
      
      let scrollPosition = target.scrollTop
      this.crntScrollPosition = scrollPosition
      
      // console.log("scrollPosition ="+ scrollPosition +"/ scrollHeight="+scrollHeight + " / clientHeight="+clientHeight)      
    },
  }
}
</script>
